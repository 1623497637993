import * as React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout/layout"
import TxtImg from "../../components/TxtImg/TxtImg"
import Text from "../../components/Text/Text"
import Console from "../../components/Console/Console"
import Button from "../../components/Button/Button"

function NeuroOptometry({ data }) {
    return (
        <Layout>
            <Helmet>
                <meta name="description" content="" />
            </Helmet>
            <section style={{paddingTop: 'var(--space-5)'}}>
                <TxtImg
                    imgFirst={true}
                    split="half"
                    imgSrc={data.eyemindLg}
                    imgAlt="Dr. Christian French, Consulting Optometrist vision therapy logo"
                    removeShadow={true}
                >
                    <h2>What is Neuro-optometry?</h2>
                    <p>Neuro-optometry is a specialised branch of optometry that focuses on understanding and treating vision problems related to neurological conditions, including brain injuries. Importantly, this involves looking at what happens to visual information once it arrives at the brain from the eyes.</p>
                    <p>These conditions can affect how the eyes and brain work together, leading to issues like double vision, blurred vision, difficulty focussing, moving about, balance and co-ordination. Sometimes it can be difficult to describe exactly what is happening to vision, so even <em>"it just doesn't feel right"</em> or <em>"it's not like it used to be"</em> can be enough to get started.</p>
                </TxtImg>
            </section>
            <section style={{paddingTop: 0}}>
                <Text>
                    <h2>Brain injury & post-trauma vision syndrome</h2>
                    <p>Even a mild brain injury or concussion can have a significant impact on daily life. Studies have shown that in the region of 75% of all brain injuries result in some form of visual disturbance. Post-Trauma Vision Syndrome (PTVS) refers to a range of vision problems that can occur following a brain injury.</p>
                    <p>It encompasses a variety of visual problems that may arise after a brain injury, such as a concussion or traumatic brain injury (TBI). These vision issues can affect how the eyes work together and how visual information is processed by the brain.</p>
                    <p>PTVS can manifest in different ways, and symptoms may vary from person to person. Some common symptoms include:</p>
                    <ul>
                        <li>Blurred or double vision</li>
                        <li>Sensitivity to light (photophobia)</li>
                        <li>Difficulty reading or focussing</li>
                        <li>Balance problems</li>
                        <li>Eye strain or headaches</li>
                        <li>Problems with depth perception or judging distances</li>
                        <li>Difficulties processing or integrating vision with other senses</li>
                        <li>Visual field loss or visual disturbances</li>
                    </ul>
                </Text>
            </section>
            <section style={{paddingTop: 0}}>
                <Text>
                    <h2>What is visual rehabilitation?</h2>
                    <p>Visual rehabilitation is a personalised programme designed to address specific vision problems resulting from brain injury. It involves various therapies and exercises aimed at improving visual skills, such as eye tracking, focusing, and depth perception. Additionally, specialised optical solutions can help; such as precision tints, filters and prisms.</p>
                    <p>The goal is to strengthen and enhance visual function and independence in daily activities. The body is very resilient, and it is possible to help reconfigure our visual system through a dedicated neuro-optometric assessment. This often includes incorporating other senses and skills such as motor and balance.</p>
                    <p>Every person is different and every brain injury is different, therefore neuro-optometry consultations are not prescriptive; an individual, tailored programme of tests and exercises is compiled in advance of your appointment to fully explore and understand your vision and symptoms.</p>
                    <Console variant="center">
                        <h3>Key components of visual rehabilitation</h3>
                        <ul>
                            <li><strong style={{textDecoration: 'underline'}}>Vision Assessment:</strong> A comprehensive evaluation to identify specific visual deficits and areas needing improvement.</li>
                            <li><strong style={{textDecoration: 'underline'}}>Customised Therapy:</strong> Tailored exercises and activities to target and strengthen weak visual skills.</li>
                            <li><strong style={{textDecoration: 'underline'}}>Assistive Devices:</strong> Utilisation of specialised tools like prism lenses or visual aids to enhance vision.</li>
                            <li><strong style={{textDecoration: 'underline'}}>Co-ordination with Healthcare Providers:</strong> Collaboration with neuro-physios, therapists, and other specialists to ensure holistic care and optimal outcomes.</li>
                        </ul>
                    </Console>
                </Text>
            </section>
            <section style={{paddingTop: 0}}>
                <Text>
                    <h2>Visual Stress</h2>
                    <p>Visual stress is a condition resulting from sensory overload in our visual system. Everyone will experience visual stress to some degree (e.g. the discomfort felt when looking at a finely-striped shirt), however some people will find the sensations and symptoms highly debilitating. It occurs when certain visual patterns or stimuli trigger discomfort or strain in the eyes and brain and manifests with symptoms like headaches, eye strain, blurred vision, and sensitivity to light or glare; especially in artificial lighting and supermarkets.</p>
                    <p>These symptoms can significantly impact daily activities, especially those involving prolonged visual concentration. Visual stress is associated with dyslexia, however they are separate entities and you can have one without the other. Visual stress has also been reported in patients who have suffered a stroke or brain injury.</p>
                </Text>
            </section>
            <section style={{paddingTop: 0}}>
                <Text>
                    <h2>Visual Snow Syndrome</h2>
                    <p>Visual snow syndrome can be considered the visual equivalent of tinnitus. The exact cause of visual snow syndrome is not fully understood, but it is believed to involve abnormal processing of visual signals in the brain. Certain factors, such as migraines, head injuries, or drug use, may trigger or exacerbate symptoms in some individuals.</p>
                    <p>Visual snow syndrome is characterized by the perception of tiny, flickering dots or static-like patterns across the visual field. These visual disturbances can resemble "static" on a television screen and may be accompanied by other visual symptoms such as:</p>
                    <ul>
                        <li>Light sensitivity</li>
                        <li>Reduced night vision</li>
                        <li>Excessive floaters or flashing lights</li>
                        <li>After-images</li>
                    </ul>
                    <p>Visual snow syndrome is also associated with systemic symptoms, such as:</p>
                    <ul>
                        <li>Insomnia</li>
                        <li>Depersonalisation</li>
                        <li>Brain fog and confusion</li>
                        <li>Tingling</li>
                        <li>Sensory hypersensitivity</li>
                        <li>Tinnitus</li>
                    </ul>
                </Text>
            </section>
            <section style={{paddingTop: 0}}>
                <Text>
                    <h2>What to expect from a neuro-optometric consultation</h2>
                    <p>Prior to your visit, it is important to establish what problems you are currently experiencing and what we hope to achieve from an assessment. I offer a free 15-minute consultation to discuss this and decide whether an appointment is appropriate. It is also an opportunity for you to ask questions and discuss the process in more detail.</p>
                    <p>Before you attend, you will be asked to complete a short questionnaire. This is designed to capture your current symptoms and incorporates a number of internationally recognised symptom surveys; giving us measures that we can use to track symptom improvement during your therapy.</p>
                    <p>The consultation itself is 2-hours, although this can be split across two separate visits if you become fatigued. Following the appointment, there may be a number of assistive devices which may be recommended; such as lenses, tints and prisms. In addition, there may be a range of therapy-based exercises that you may need to complete in advance of your next appointment.</p>
                    <p>All of the findings and recommendations will be included in a comprehensive medical report that you will receive shortly after your consultation. This can be shared with other professionals and employers if necessary.</p>
                </Text>
            </section>
            <section style={{paddingTop: 0}}>
                <Text>
                    <h2>Do I need a referral?</h2>
                    <p>No; you are able to self-refer if you feel that a neuro-optometric consultation may be beneficial. I also accept referrals from other professionals, such as neuro-physios, occupational therapists and case managers. I also am happy to take on medico-legal case work. If you have a patient or client that you feel may benefit from an assessment, please get in touch.</p>
                    <Console variant="center">
                        <p style={{fontSize: 'var(--type-xs)'}}><strong style={{textDecoration: 'underline'}}>Disclaimer:</strong> Vision therapy and visual rehabilitation aims to help lessen or alleviate visual symptoms. It is not designed to diagnose brain conditions, nor does it aim to 'cure' them. By booking an appointment you will be accepting that outcomes cannot be guaranteed and that referral and communication with other specialisms may be necessary to provide all-round support.</p>
                    </Console>
                </Text>
            </section>
            <section style={{backgroundColor: 'var(--neutral-900)'}}>
                <TxtImg
                    imgFirst={false}
                    split="half"
                    imgSrc={data.eyemindSm}
                    imgAlt="Dr. Christian French, Consulting Optometrist vision therapy logo"
                    removeShadow={true}
                >
                    <h2>Make an appointment</h2>
                    <p>In order to understand your (or your client’s) symptoms and aims for visual rehabilitation, I offer a free 15-minute virtual consultation to discuss the process and give an indication regarding fees.</p>
                    <Button to="/contact/" external={false} type="primarySolid" margin="var(--space-5) 0 0 0">Get in touch</Button>
                </TxtImg>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query {
        eyemindLg: file(relativePath: {eq: "CFCO_EyeMind_colour.png"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 800
                    placeholder: BLURRED
                    formats: WEBP
                )
            }
        }
        eyemindSm: file(relativePath: {eq: "CFCO_EyeMind_colour.png"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 500
                    placeholder: BLURRED
                    formats: WEBP
                )
            }
        }
    }
`

export default NeuroOptometry